// todo we should return 404 if any of the params are undefined or null.
export default defineNuxtRouteMiddleware((to) => {
  if (to.params.mapId === 'undefined') {
    throw createError({
      statusCode: 404,
      statusMessage: 'Page not found',
      fatal: true
    })
  }
})
